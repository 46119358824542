import { Component, OnInit } from '@angular/core';
import { faEnvelope, faMapMarker, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {
  faMail = faEnvelope;
  faFacebook = faFacebook;
  faMarker = faMapMarker;
  faPhone = faPhone;

  imageUrlArray = ['assets/images/banner-1.jpg', 'assets/images/banner-3.jpg' , 'assets/images/banner-6.jpg'];
  constructor() { }

  ngOnInit() {
  }

}
