import { Component } from '@angular/core';
import {AuthService} from './auth.service';
import {OktaAuthService} from '@okta/okta-angular';
import {AngularFireAuth} from '@angular/fire/auth';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Flores in Terra';
  menuOpen = false;
  currentYear = new Date().getFullYear();
  constructor(private auth: AuthService, public oktaAuth: OktaAuthService, private afAuth: AngularFireAuth) {
    this.afAuth.signInAnonymously().then(user => {
      // console.log('Anonymous Sign In Success', user);
    });
  }

  authenticate() {

    this.auth.authenticate();
  }
}
