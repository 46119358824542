import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {HomepageComponent} from './homepage/homepage.component';
import {OpeningstijdenComponent} from './openingstijden/openingstijden.component';
import {OpendagenComponent} from './opendagen/opendagen.component';
import {AanbodComponent} from './aanbod/aanbod.component';
import {ContactComponent} from './contact/contact.component';
import {OktaCallbackComponent} from '@okta/okta-angular';
import {VacaturesComponent} from './vacatures/vacatures.component';
import {VacaturesDetailComponent} from './vacatures-detail/vacatures-detail.component';
import {MateComponent} from './mate/mate.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  {
    path: 'home',
    component: HomepageComponent,
  },
  {
    path: 'openingstijden',
    component: OpeningstijdenComponent,
  },
  {
    path: 'opendagen',
    component: OpendagenComponent,
  },
  {
    path: 'aanbod',
    component: AanbodComponent,
  },
  {
    path: 'mate',
    component: MateComponent,
  },
  {
    path: 'vacatures',
    component: VacaturesComponent,
  },
  {
    path: 'vacatures/:id/:name',
    component: VacaturesDetailComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
  {
    path: 'implicit/callback',
    component: OktaCallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
