import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireStorage} from '@angular/fire/storage';
import {AngularFireAuth} from '@angular/fire/auth';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-aanbod',
  templateUrl: './aanbod.component.html',
  styleUrls: ['./aanbod.component.scss']
})
export class AanbodComponent implements OnInit {

  plants: Plant[];
  showImage: boolean;
  openImage: string;
  scrollRequired: boolean;
  scrollDist: number;

  constructor(private afAuth: AngularFireAuth,
              private firestore: AngularFirestore,
              private storage: AngularFireStorage,
              @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.firestore.collection('floriday').doc('plants').valueChanges().subscribe((res: any) => {
      this.plants = JSON.parse(res.plants);
      this.plants.sort((a, b) => {
        const textA = a.tradeItemName.nl;
        const textB = b.tradeItemName.nl;
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      });
    });
  }

  getPlantUrl(url: string) {
    return url.replace('.jpg', '.jpg?width=360');
  }

  getPotsize(characteristics: [{ vbnCode: string; vbnValueCode: string }]) {
    return Number(characteristics.find(char => char?.vbnCode === 'S01')?.vbnValueCode);
  }

  getPlantHeight(characteristics: [{ vbnCode: string; vbnValueCode: string }]) {
    return Number(characteristics.find(char => char?.vbnCode === 'S02')?.vbnValueCode);
  }

  openImg(url: string) {
    this.showImage = true;
    this.handleOverflow(false);
    this.openImage = url;
    console.log(url);
  }

  /**
   * handle overflow
   * @param check {boolean}
   *
   * If the overlay is triggered we want to remove scrolling from the page
   * and at the same time not move the content due to removing the scrollbar.
   */
  handleOverflow(check: boolean): void {
    this.scrollRequired = this.document.body.offsetHeight > window.innerHeight;
    if (!check) {
      if (this.scrollRequired) {
        this.scrollDist = window.pageYOffset;
        this.document.body.classList.add('no-scroll');
        this.document.body.style.top = '-' + this.scrollDist + 'px';
      }
    } else {
      this.document.body.classList.remove('no-scroll');
      this.document.body.removeAttribute('style');
      window.scrollTo(0, this.scrollDist);
    }
  }

  closeImg() {
    this.showImage = false;
    this.handleOverflow(true);
  }
}


interface Plant {
  'tradeItemReference': string;
  'tradeItemVersion': number;
  'isDeleted': boolean;
  'sequence': number;
  'tradeItemId': string;
  'vbnProductCode': number;
  'supplierArticleCode': string;
  'characteristics': [{ 'vbnCode': string, 'vbnValueCode': string }];
  'customerOrganizationIds': [];
  'hiddenFromCustomer': boolean;
  'packingConfigurations': [
    { 'primary': boolean,
      'piecesPerPackage': number,
      'bunchesPerPackage': any,
      'package': { 'vbnPackageCode': number, 'customPackageId': any },
      'packagesPerLayer': number,
      'layersPerLoadCarrier': number,
      'transportHeightInCm': any,
      'loadCarrier': string,
      'additionalPricePerPiece': any, 'photoUrl': any } ];
  'photos': [
    {
      'primary': boolean,
      'seasonalPeriod': any,
      'type': string,
      'url': string
    }
    ];
  'seasonalPeriods': [];
  'tradeItemName': { 'nl': string }; 'articleGtin': any; 'botanicalNames': []; 'countryOfOriginIsoCodes': [];
}
