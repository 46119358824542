import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  grantType = 'client_credentials';
  clientId = '0oarccbcdcadhwlRx0h7';
  clientSecret = 'JhAMeffz595I_BOG3KtA2-Y4rXvSAv-fI7yxdud_';
  scope = 'role:app catalog:read';
  xApiKey = 'a4e64aaf4ee560f77310099b6b0f9a70f0941131d061b22a';

  bearerToken;

  itemsUrl = 'https://api.floriday.io/apps/trade-items';

  headers: HttpHeaders = new HttpHeaders()
    .append('Accept', 'application/json')
    .append('Content-Type', 'application/x-www-form-urlencoded');

  constructor(private httpClient: HttpClient) {

  }

  authenticate() {
    const body = `grant_type=${this.grantType}&client_id=${this.clientId}&client_secret=${this.clientSecret}&scope=${this.scope}`;

    // formData.append('client_secret', this.client_secret);
    // formData.append('scope', this.scope);
    this.httpClient.post<{token_type: string, expires_in: number, access_token: string, scope: string}>
    ('https://floriday.okta-emea.com/oauth2/default/v1/token',
      body,
      {headers: this.headers}
      ).subscribe(res => {
        this.bearerToken = 'Bearer ' + res.access_token;
    });
  }

  getTradeItems() {
    if(this.bearerToken) {
      const httpHeaders = new HttpHeaders();
      httpHeaders
        .append('accept', 'application/json')
        .append('Authorization', this.bearerToken)
        .append('x-api-key', this.xApiKey);
      return this.httpClient.get(this.itemsUrl, {headers: httpHeaders});
    }
  }
}
