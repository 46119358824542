import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomepageComponent } from './homepage/homepage.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { OpeningstijdenComponent } from './openingstijden/openingstijden.component';
import { OpendagenComponent } from './opendagen/opendagen.component';
import { ContactComponent } from './contact/contact.component';
import { AanbodComponent } from './aanbod/aanbod.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {
  OKTA_CONFIG,
  OktaAuthModule,
} from '@okta/okta-angular';
import {IvyCarouselModule} from 'angular-responsive-carousel';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {AngularFireStorageModule} from '@angular/fire/storage';
import {AngularFireAuthModule} from '@angular/fire/auth';
import {AngularFireAnalyticsModule} from '@angular/fire/analytics';
import {VacaturesComponent} from './vacatures/vacatures.component';
import {VacaturesDetailComponent} from './vacatures-detail/vacatures-detail.component';
import {MateComponent} from './mate/mate.component';
const config = {
  clientId: '0oarccbcdcadhwlRx0h7',
  issuer: 'https://idm.staging.floriday.io/oauth2/ausmw6b47z1BnlHkw0h7',
  redirectUri: 'http://localhost:4200/implicit/callback',
  scopes: ['role:app', 'catalog:read'],
  pkce: true
}

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    MateComponent,
    OpeningstijdenComponent,
    OpendagenComponent,
    ContactComponent,
    AanbodComponent,
    VacaturesComponent,
    VacaturesDetailComponent
  ],
  imports: [
    BrowserModule,
    OktaAuthModule,
    AppRoutingModule,
    FontAwesomeModule,
    FormsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAnalyticsModule,
    AngularFireStorageModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    IvyCarouselModule
  ],
  providers: [{ provide: OKTA_CONFIG, useValue: config },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
