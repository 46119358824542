import { Component, OnInit } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'app-vacatures',
  templateUrl: './vacatures.component.html',
  styleUrls: ['./vacatures.component.scss']
})
export class VacaturesComponent implements OnInit {

  vacatures: Observable<any[]>;
  private _jsonURL = 'assets/vacatures.json';
  constructor(private http: HttpClient, private router: Router) {
    this.vacatures =this.getJSON()
  }
  public getJSON(): Observable<any> {
    return this.http.get(this._jsonURL);
  }

  ngOnInit() {
  }

  goToDetail(vacature: any) {
    const vacatureName = vacature.name.replaceAll(/ /g, '_');
    this.router.navigate([`vacatures/${vacature.id}/${vacatureName}`]);
  }
}
