import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  aFormGroup: FormGroup;
  notFilled: boolean;
  captcha: boolean;
  sendSucces: boolean;
  constructor(private http: HttpClient) { }

  ngOnInit() {
    this.aFormGroup = new FormGroup({
      name: new FormControl('', Validators.required),
      number: new FormControl('', Validators.required),
      mail: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      recaptcha: new FormControl('', Validators.required),
    });
  }

  sendMail() {
    this.http.get<any>(`https://us-central1-floresinterra-78fdf.cloudfunctions.net/sendMail?dest=info@floresinterra.nl&from=${this.aFormGroup.value.name}&mail=${this.aFormGroup.value.mail}&number=${this.aFormGroup.value.number}&message=${this.aFormGroup.value.message}`).subscribe(res => {
      console.log(res);
      if (res.response === 'Sended') {
        this.aFormGroup.reset();
        this.sendSucces = true;
      }
    });
  }
}
